import { STEP_IS_FINISH, RESET_FINISH,RESET_ALL_FINISH } from '../../constants';


const initialState = {
    finishedSteps : []
}
export function stepReducer(state = initialState , action)  {
    switch(action.type) {
    case STEP_IS_FINISH:
        return {
            ...state,
            finishedSteps: [...state.finishedSteps, action.payload],
        };
    case RESET_FINISH:
        return {
            ...state,
            finishedSteps: [...state.finishedSteps.filter(step => step !== action.payload)] ,
        };    
        case RESET_ALL_FINISH:
        return {
            finishedSteps: []
        };
    default:
      return state;
  }
};


