import React from 'react';
import { connect } from 'react-redux';



class Sticky extends React.Component {

    render() {
        const collectivity = "id-360002362000"
        return (
            ( this.props.order !== undefined ) && ( !this.props.loading ) ?
                <div className="sticky">
                    <p>{ ( this.props.show && this.props.collectivity && this.props.collectivity.values ) ? ( ( this.props.collectivity.values[ collectivity ] === "métropole_/_grande_agglomération" ) ? 'Métropole' : this.props.collectivity.values[ collectivity ] ) : '' }</p>
                </div> : ''
        )
    }
}

export function mapStateToProps( state ) {
    return {
        collectivity: state.form.tdj_form,
        loading: state.tdj_form.loading,
        order: state.tdj_form.form_order,

    }
};

export default connect( mapStateToProps, null )( Sticky );
