import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import Vimeo from "@u-wave/react-vimeo";

import Menu from "./../Menu";
import pdf_picto from "./../../img/pictos/pdf_picto.svg";

import logo_Jo_white from "./../../img/logos/paris2024White.svg";
import check from "./../../img/pictos/check_pink.svg";
import CocaCola from "./../../img/logos/COCA.svg";
import Airbnb from "./../../img/logos/AIRBNB.svg";
import Alibaba from "./../../img/logos/ALIBABA.svg";
import Allianz from "./../../img/logos/ALLIANZ.svg";
import Bridgestone from "./../../img/logos/BRIDGESTONE.svg";
import Atos from "./../../img/logos/ATOS.svg";
import Intel from "./../../img/logos/INTEL.svg";
import Omega from "./../../img/logos/OMEGA.svg";
import Orange from "./../../img/logos/ORANGE.svg";
import Panasonic from "./../../img/logos/PANASONIC.svg";
import Samsung from "./../../img/logos/SAMSUNG.svg";
import Toyota from "./../../img/logos/TOYOTA.svg";
import Visa from "./../../img/logos/VISA.svg";
import Bpce from "./../../img/logos/BPCE.svg";
import Edf from "./../../img/logos/EDF.svg";
import CoqSportif from "./../../img/logos/COQ_SPORTIF.svg";
import Pg from "./../../img/logos/PG.svg";

export const Link = (props) => {
  return (
    <a
      className="pdf__link"
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={pdf_picto} alt="télécharger le pdf" />
      <p> {props.label} </p>
    </a>
  );
};

const Home = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className="page__container">
      <Menu />
      <div className="video__container" id="video__container">
        <div id="shape" className="shape"></div>
        <div className="embed__container" id="embed__container">
          <Vimeo
            video="342275460"
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
          />
        </div>

        <div
          className={classNames("video__titles", {
            hidden: isPlaying,
          })}
        >
          <div>
            <h1>Collectivités, devenez actrices des Jeux !</h1>
            <h4>
              Le label qui fait des collectivités
              <br /> territoriales des actrices des Jeux.
            </h4>
            <div className="bar"></div>
          </div>
        </div>
      </div>
      <div className="home__content">
        <div className="white__part">
          <h3>
            Les Jeux Olympiques et Paralympiques représentent une opportunité
            d’émotions,
            <br /> d’actions et de promotion hors norme.
          </h3>
          <p>
            <strong>Le label Terre de Jeux 2024</strong> permet à toutes les
            collectivités territoriales qui partagent la conviction que le sport
            change les vies de bénéficier de cette énergie unique.
            <br />
            <br />
            <strong>Devenir Terre de Jeux 2024</strong>, c’est s’engager à
            contribuer à faire vivre à tous les émotions des Jeux, changer le
            quotidien des gens grâce au sport et permettre au plus grand nombre
            de vivre l’aventure olympique et paralympique dès maintenant.
            <br />
            <br />
            <strong>Devenir Terre de Jeux 2024</strong>, c’est contribuer à
            faire des Jeux un projet national.
          </p>
          <div>
            <Link
              url="../plaquette_paris2024.pdf"
              label="La plaquette de présentation du label"
            />
            <Link url="../guide_du_candidat.pdf" label="Le guide du candidat" />
            <Link url="../faq.pdf" label="La Faq" />
          </div>

          <NavLink exact to="/exploreterredejeux2024">
            <button type="" style={{ marginRight: 20 }}>
              #exploreTerredeJeux2024
            </button>
          </NavLink>

          <NavLink exact to="/formulaire/etape-0">
            <button className="hideOnMobile">Je candidate </button>
          </NavLink>
        </div>
        <div className="black__part">
          <h3>
            Devenir Terre de Jeux
            <br /> 2024 c’est bénéficier
          </h3>
          <div className="black__part__list">
            <div>
              <img src={check} alt="check" />{" "}
              <p>D'une identité exclusive pour s’associer aux Jeux</p>
            </div>
            <div>
              <img src={check} alt="check" />{" "}
              <p>
                D'un accès privilégié aux informations, outils et événements de
                Paris 2024
              </p>
            </div>
            <div>
              <img src={check} alt="check" />{" "}
              <p>Du partage d’expérience avec une communauté engagée</p>
            </div>
            <div>
              <img src={check} alt="check" />{" "}
              <p>
                Du coup de projecteur des Jeux pour promouvoir vos actions et
                votre territoire
              </p>
            </div>
            <div>
              <img src={check} alt="check" />{" "}
              <p>
                De la possibilité de candidater pour devenir Centre de
                Préparation aux Jeux
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="logo__container">
        <div className="logo__title">
          <div className="bar"></div>
          <span>Partenaires Mondiaux</span>
          <div className="bar"></div>
        </div>
        <div className="logos">
          <div className="logos__firstLine">
            <img src={Airbnb} alt="logo" />
            <img src={Alibaba} alt="logo" />
            <img src={Allianz} alt="logo" />
            <img src={Atos} alt="logo" className="smallLogo" />
            <img src={Bridgestone} alt="logo" />
            <img src={CocaCola} alt="logo" className="smallLogo" />
            {/* </div> */}
            {/* <div className="logos__secondLine"> */}
            <img src={Intel} alt="logo" />
            <img src={Omega} alt="logo" />
            <img src={Panasonic} alt="logo" />
            <img src={Pg} alt="logo" />
            <img src={Samsung} alt="logo" />
            <img src={Toyota} alt="logo" />
            <img src={Visa} alt="logo" />
            {/* </div> */}
          </div>
        </div>
        <div className="logo__title">
          <div className="bar"></div>
          <span>Partenaires Premium</span>
          <div className="bar"></div>
        </div>
        <div className="premium">
          <img src={Bpce} alt="logo" />
          <img src={Edf} alt="logo" />
          <img src={Orange} alt="logo" />
        </div>
        <div className="logo__title">
          <div className="bar"></div>
          <span>Partenaire Officiel</span>
          <div className="bar"></div>
        </div>
        <div className="officials--partners">
          <img src={CoqSportif} alt="logo" />
        </div>
        {/* <div className="logo__title">
          <div className="bar"></div>
          <span>Supporters Officiels</span>
          <div className="bar"></div>
        </div>
        <div className="officials--supporters">
          <img src={CoqSportif} alt="logo" />
        </div> */}
      </div>
      <div className="footer__container">
        <div className="title__and__logo">
          <img src={logo_Jo_white} className="logo__footer" alt="logo" />
          <h3>
            Le label qui fait des collectivités
            <br /> territoriales des actrices de Paris 2024.
          </h3>
        </div>

        <div className="footer__links">
          <a
            href="https://www.paris2024.org/fr/mentions-legales/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mentions légales
          </a>

          <a
            href="../conditions_generales_utilisation_terre_de_jeux_2024.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Conditions Générales
          </a>
          <a
            href="https://sop.paris2024.org/app/uploads/2020/01/Paris-2024-Reglement-Challenge-SOP-20200127.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Règlement jeu concours
          </a>
          <a
            href="../resultats-top-10.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Résultats jeu concours
          </a>
        </div>
      </div>
    </div>
  );
};

export default connect(null)(Home);
