import slide1 from "../img/mapSlides/1.jpg";
import slide2 from "../img/mapSlides/2.jpg";
import slide3 from "../img/mapSlides/3.jpg";
import slide4 from "../img/mapSlides/4.png";
import slide5 from "../img/mapSlides/5.png";
import slide6 from "../img/mapSlides/6.png";
import slide7 from "../img/mapSlides/7.jpg";
import slide8 from "../img/mapSlides/8.jpg";
import slide9 from "../img/mapSlides/9.jpg";
import slide10 from "../img/mapSlides/10.jpg";
import slide11 from "../img/mapSlides/11.jpg";
import slide12 from "../img/mapSlides/12.jpg";

export const slides = [
  {
    path: slide1,
  },
  {
    path: slide2,
  },
  {
    path: slide3,
  },
  {
    path: slide4,
  },
  {
    path: slide5,
  },
  {
    path: slide6,
  },
  {
    path: slide7,
  },
  {
    path: slide8,
  },
  {
    path: slide9,
  },
  {
    path: slide10,
  },
  {
    path: slide11,
  },
  {
    path: slide12,
  },
];
