import {
  STEP_IS_FINISH,
  RESET_FINISH,
  RESET_ALL_FINISH
} from "../../constants";

export function onStepIsFinish(pathName) {
  return {
    type: STEP_IS_FINISH,
    payload: pathName
  };
}

export function onResetFinish(pathName) {
  return {
    type: RESET_FINISH,
    payload: pathName
  };
}

export function onResetAllFinish(pathName) {
  return {
    type: RESET_ALL_FINISH
  };
}
