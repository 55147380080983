export const FETCH_FORM = "FETCH_FORM";
export const FETCH_ORDER_FORM = "FETCH_ORDER_FORM";
export const SUBMIT_FORM = "SUBMIT_FORM";
export const SUBMIT_MAIL = "SUBMIT_MAIL";
export const STEP_IS_FINISH = "STEP_IS_FINISH";
export const RESET_FINISH = "RESET_FINISH";
export const RESET_ALL_FINISH = "RESET_ALL_FINISH";
export const mailchimpOptions = {
  templates: {
    hasAccepted: "WelcomeMailCPJTrueNewCharte",
    hasRefused: "WelcomeMailCPJFalseNewCharte"
  },
  templateContentName: "formtdj",
  key: "ZebOZXHZRGmYG82Q3QAJWg",
  subject: "Candidature Terre de Jeux 2024",
  from: "contact@terredejeux2024.support",
  fromName: "Terre de Jeux 2024",
  replyTo: "contact@terredejeux2024.support"
};
