import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import Menu from "./../../components/Menu";
import MapSlider from "./../../components/Slider";

import { Link } from "./../../components/Home";
const Explore = () => {
  return (
    <div className="page__container">
      <Menu logoBlack />
      <div className="exlore__container">
        <div className="left__part">
          <h1>
            #Explore
            <span>TerreDeJeux2024</span>
          </h1>
          <h2>
            L’objectif de l’opération est de valoriser les parcours et
            itinéraires sportifs à réaliser dans les territoires Terre de Jeux
            2024.
            <br />
            Remplissez le modèle prêt à l'emploi et partagez vos plus beaux
            terrains de jeux sur les réseaux sociaux de votre collectivité !
            <br />
            <br />
            #ExploreTerreDeJeux2024
          </h2>
          <Link
            url="../carte_ExploreTerreDeJeux.pptx"
            label="Télécharger ici votre maquette prête à l’emploi"
          />
          <div className="mapSlider__container">
            <MapSlider />
          </div>
        </div>

        <div className="right__part">
          <h2 style={{ width: "100%" }}>Découvrez les derniers tweets</h2>
          <TwitterTimelineEmbed
            sourceType="collection"
            id="1281511634641522688"
            noHeader
            noFooter
            noborders
            linkColor="#ff5d71"
            placeholder=""
            autoHeight
            options={{ width: 600, height: "90vh" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Explore;
