 /*  
  • 1 
  Map the ticket list
  • 2 
  For each ticket we return a formatted payload
  • 3 
  We remove unnecessary values and we map the array of ordered tickets (order in argument) to collect
  ticket index, we add this value to the payload (position": order.indexOf(t.id)) 
  • 4 
  We reorder the fields
  • 5 
  We only keep the tickets between c and d (passed in arguments) 
  */

export const formatTicket = (ticket_fields,order,c,d) => {
    return ticket_fields.map(t => {
      return {
        "active": t.active,
        "description":t.description,
        "id": t.id,
        "position": order.indexOf(t.id),
        "regexp_for_validation": t.regexp_for_validation,
        "required": t.required_in_portal,
        "title": t.title,
        "type": t.type,
        "custom_field_options":t.custom_field_options && 
          t.custom_field_options.map(t => {
            return {"label": t.name, "value": t.value}
          })
      }
    }).sort((a,b)=> a.position - b.position).slice(c,d)
  
   
}
export const removeFromArray = ( original, remove ) =>  {
  return original.filter( value => !remove.includes( value.id ) );
}
