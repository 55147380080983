import React from 'react';
import { connect} from 'react-redux';

import Timeline from './../../components/Timeline'

class TimelineRender extends React.Component {

    render(){
      return (
      <Timeline finishedSteps={this.props.finishedSteps}/>
      )
    }
}

export function mapStateToProps(state) {
  return {
    finishedSteps : state.finishedSteps.finishedSteps
  }
};

export default connect(mapStateToProps)(TimelineRender);
