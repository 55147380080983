import React from "react";
import { loadProgressBar } from "axios-progress-bar";
import { connect } from "react-redux";
import Form from "../../../components/ReduxForm";
import {
  onStepIsFinish,
  onResetFinish,
  onResetAllFinish,
} from "../../../redux/actions/steps";
import { submitFormAndMail } from "../../../redux/thunk/submitForm";

import { formatTicket } from "../../../utils/formatPayload";
import "axios-progress-bar/dist/nprogress.css";
import { reset } from "redux-form";
class RenderForm extends React.Component {
  onClickBackward = (e, type, population) => {
    this.props.history.push({
      pathname: "etape-6",
    });
  };

  handleSubmit = (values) => {
    const path = this.props.location.pathname.replace("/formulaire/", "");
    this.props.onStepIsFinish(path);
    loadProgressBar();
    this.props.onSubmitForm(values);
  };

  componentWillReceiveProps() {
    if (this.props.response.response.status === 201) {
      this.props.history.push({
        pathname: "success",
      });
    } else if (this.props.response.error) {
      this.props.history.push({
        pathname: "error",
      });
    } else {
      return;
    }
  }

  render() {
    const path = this.props.location.pathname.replace("/formulaire/", "");
    return (
      this.props.order !== undefined &&
      !this.props.loading && (
        <Form
          ticket_fields={formatTicket(
            this.props.ticket_fields,
            this.props.order,
            53,
            55
          )}
          title="7. Conditions Générales"
          category="pilier"
          onClickBackward={(e) => this.onClickBackward(e)}
          BackwardBtnValue=""
          ForwardBtnValue=""
          onSubmit={this.handleSubmit.bind(this)}
          canSubmit
          showPdf
          stepIsFinish={(e) => this.props.onStepIsFinish(path)}
          resetFinish={(e) => this.props.onResetFinish(path)}
          error={this.props.response.error}
        />
      )
    );
  }
}

export function mapStateToProps(state) {
  return {
    loading: state.tdj_form.loading,
    error: state.tdj_form.error,
    ticket_fields: state.tdj_form.ticket_fields,
    order: state.tdj_form.form_order,
    values: state.form.tdj_form,
  };
}

export function mapDispatchToProps(dispatch, state) {
  return {
    onStepIsFinish: (path) => dispatch(onStepIsFinish(path)),
    onResetFinish: (path) => dispatch(onResetFinish(path)),
    onSubmitForm: (values) => dispatch(submitFormAndMail(values)),
    onResetForm: () => {
      return dispatch(reset("tdj_form")), dispatch(onResetAllFinish());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderForm);
