import { SUBMIT_FORM,RESET_ALL_FINISH } from '../../constants';


const initialState = {
    response : []
}

export function submitFormReducer(state = initialState , action)  {
    switch(action.type) {
        case `${SUBMIT_FORM}_PENDING`:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case `${SUBMIT_FORM}_FULFILLED`:
            return {
                ...state,
                loading: false,
                error: false,
                response : action.payload
            };
        case `${SUBMIT_FORM}_REJECTED`:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case RESET_ALL_FINISH:
            return {
                response : []
            };    
        default:
          return state;
  }
};


