import { SUBMIT_FORM, SUBMIT_MAIL, mailchimpOptions } from "../../constants";
import axios from "axios";
import {
  EMAIL,
  FIRSTNAME,
  NAME,
  COLLECTIVITY_NAME,
  INSEE,
  CPJ,
} from "./../../static/fields_constants";
import { labels } from "./../../static/labels";

const config = {
  headers: {
    Authorization:
      "Bearer 1763f2abd768a4690c44810eb5a66c3078b8cde75a9ff8f190cd9e7e9951a909",
    "Content-Type": "application/json",
  },
};

export function submitForm(data) {
  let formatPayload = {
    request: {
      requester: {
        name: data[FIRSTNAME] + " " + data[NAME],
        email: data[EMAIL],
      },
      assignee_id: 360000718839,
      status: "new",
      subject: data[COLLECTIVITY_NAME] + " - " + data[INSEE],
      comment: {
        body: "Formulaire Terre de Jeux 2024",
      },
    },
  };

  let formatData = [];
  Object.keys(data).forEach((e) => {
    return formatData.push({ id: e.replace("id-", ""), value: data[e] });
  });
  formatPayload.request["custom_fields"] = formatData;
  return {
    type: SUBMIT_FORM,
    payload: axios.post(
      "https://paris2024.zendesk.com/api/v2/requests.json",
      formatPayload,
      config
    ),
  };
}

export function submitMail(data) {
  //Format Mail

  //Ie fix polyfill for object entries
  if (!Object.entries) {
    Object.entries = function (obj) {
      var ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i);
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

      return resArray;
    };
  }

  const html = Object.entries(data).map(([key, value]) => {
    key = key.replace("id-", "");
    // VALUE = TRUE  || CPJ ACCEPTED
    if (value === true || value === "has_accepted_cpj") {
      value = "oui";

      if (key === "360002421339") {
        return `<br/><br/><h3>Engagement</h3>
                    <hr/>
                    <strong>${labels[key] && labels[key].description}</strong>
                    <p>${value}</p>
                    <hr/>`;
      } else if (key === "360002421319") {
        return `<br/><br/><h3>Célébration</h3>
                    <hr/>
                    <strong>${labels[key] && labels[key].description}</strong>
                    <p>${value}</p>
                    <hr/>`;
      } else if (key === "360002421419") {
        return `<br/><br/><h3>Héritage</h3>
                    <hr/>
                    <strong>${labels[key] && labels[key].description}</strong>
                    <p>${value}</p>
                    <hr/>`;
      } else if (key === "360002464979") {
        return `<br/><br/><h3>Conditions Générales d'Utilisation</h3>
                    <hr/>
                    <strong>${labels[key] && labels[key].description}</strong>
                    <p>${value}</p>
                    <hr/>`;
      }
      else if (key === "360015410659") {
        return `<br/><br/><h3>Partage aux labélisés</h3>
                    <hr/>
                    <strong>${labels[key] && labels[key].description}</strong>
                    <p>${value}</p>
                    <hr/>`;
      }
      else
        return `<strong>${
          labels[key] && labels[key].description
        }</strong><p>${value}</p><hr/>`;
    }
    // VALUE =  CPJ REFUSE
    else if (value === "has_refused_cpj") {
      value = "non";
      return `<strong>${
        labels[key] && labels[key].description
      }</strong><p>${value}</p><hr/>`;
    }
    // VALUE =  FALSE
    else if (value === false) {
      //Redux form send 'false' for checkbox who are checked and then unchecked, we don't want to see this beahaviour inside mail
      return "";
    }
    // VALUE STRING
    else {
      switch (value) {
        case "size_1":
          value = "Moins de 2.500";
          break;
        case "size_2":
          value = "de 2.500 à 5.000";
          break;
        case "size_3":
          value = "de 5.000 à 10.000";
          break;
        case "size_4":
          value = "de 10.000 à 50.000";
          break;
        case "size_5":
          value = "Plus de 50.000";
          break;
        case "commune":
          value = "Commune";
          break;
        case "métropole_/_grande_agglomération":
          value = "Métropole / Grande Aglomération";
          break;
        case "département":
          value = "Département";
          break;
        case "intercommunalité":
          value = "Intercommunalité";
          break;
        case "région":
          value = "Région";
          break;
        default:
          value = value;
      }
      if (key === "360002466240") {
        return `<br/><br/><h3>Informations collectivités</h3>
                    <hr/>
                    <strong>${labels[key] && labels[key].description}</strong>
                    <p>${value}</p>
                    <hr/>`;
      } else if (key === "360002384680") {
        return `<br/><br/><h3>Coordonnées du référent</h3>
                    <hr/>
                    <strong>${labels[key] && labels[key].description}</strong>
                    <p>${value}</p>
                    <hr/>`;
      } else {
        return `<strong>${
          labels[key] && labels[key].description
        }</strong><p>${value}</p><hr/>`;
      }
    }
  });

  let markup = html.join("");

  // SEND MAILCHIMP TEMPLATE ACCORDING TO USER'S CHOICE
  let templateMail =
    data[CPJ] === "has_accepted_cpj"
      ? mailchimpOptions.templates.hasAccepted
      : mailchimpOptions.templates.hasRefused;

  let formatMail = {
    key: mailchimpOptions.key,
    template_name: templateMail,
    template_content: [
      {
        name: mailchimpOptions.templateContentName,
        content: markup,
      },
    ],
    message: {
      subject: mailchimpOptions.subject,
      from_email: mailchimpOptions.from,
      from_name: mailchimpOptions.fromName,
      to: [
        {
          email: data[EMAIL],
          name: data[FIRSTNAME] + " " + data[NAME],
          type: "to",
        },
      ],
      headers: {
        "Reply-To": mailchimpOptions.replyTo,
      },
      important: false,
      track_opens: null,
      track_clicks: null,
      auto_text: null,
      auto_html: null,
      inline_css: null,
      url_strip_qs: null,
      preserve_recipients: null,
      view_content_link: null,
      tracking_domain: null,
      signing_domain: null,
      return_path_domain: null,
      merge: true,
      merge_language: "mailchimp",
      tags: ["cpj"],
    },
    async: false,
    send_at: new Date().toISOString() || "1995-12-17T03:24:00",
  };

  return {
    type: SUBMIT_MAIL,
    payload: axios.post(
      "https://mandrillapp.com/api/1.0/messages/send-template.json",
      formatMail
    ),
  };
}
