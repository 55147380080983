import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { withRouter } from "react-router";
import classNames from "classnames";
import { Document, Page, pdfjs } from "react-pdf";

import {
  required,
  email,
  requiredCheck,
  zipCode,
  phone,
  inseeCode,
  rsxCode,
} from "./../../utils/validation";
import {
  renderCheckBox,
  renderField,
  renderSocialField,
  renderTextarea,
  renderSelect,
} from "./renderComponents";
import { samples } from "./../../static/sample";
import { getHelpMessage } from "./../../static/message";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
class Form extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  onClickBackward = (e) => {
    this.props.onClickBackward(e);
  };
  onClickForward = (e) => {
    this.props.onClickForward(e);
    this.props.stepIsFinish();
  };

  render() {
    const { handleSubmit, population, help, submitting } = this.props;
    const { pageNumber, numPages } = this.state;

    return (
      <div className="form__container">
        <form
          onSubmit={handleSubmit}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
        >
          <div className="form__titles">
            <h4>Collectivité territoriale</h4>
            <h1>{this.props.title}</h1>
            {this.props.message && <p>{this.props.message}</p>}
          </div>

          {this.props.showPdf && (
            <div>
              <Document
                file="../conditions_generales_utilisation_terre_de_jeux_2024.pdf"
                onLoadSuccess={this.onDocumentLoadSuccess}
                onLoadError={console.error}
                loading="Chargement des Conditions Générales d'Utilisation ..."
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <div className="btn__pdf__container">
                {pageNumber !== 1 ? (
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({ pageNumber: pageNumber - 1 })
                    }
                  >
                    Page précédente
                  </button>
                ) : (
                  <div> </div>
                )}
                {/* <p>Page { pageNumber } sur { numPages }</p> */}
                {pageNumber < numPages ? (
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({ pageNumber: pageNumber + 1 })
                    }
                  >
                    Page suivante
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          )}

          {this.props.ticket_fields.map((t, idx) => {
            switch (t.type) {
              case "text":
                // REgex Mail
                if (t.id === 360002466780 || t.id === 360002464439) {
                  return (
                    <Field
                      className="input__container"
                      key={t.id}
                      required={t.required}
                      name={`id-${t.id}`}
                      component={renderField}
                      type="text"
                      validate={[required, email]}
                      label={t.required ? t.title + " *" : t.title}
                    />
                  );
                }
                // REgex ZipCode
                if (t.id === 360002464779) {
                  return (
                    <Field
                      className="input__container"
                      key={t.id}
                      required={t.required}
                      name={`id-${t.id}`}
                      component={renderField}
                      type="text"
                      validate={[required, zipCode]}
                      label={t.required ? t.title + " *" : t.title}
                    />
                  );
                }
                // REgex Phone
                if (t.id === 360002466540 || t.id === 360002466820) {
                  return (
                    <Field
                      className="input__container"
                      key={t.id}
                      required={t.required}
                      name={`id-${t.id}`}
                      component={renderField}
                      type="text"
                      validate={[required, phone]}
                      label={t.required ? t.title + " *" : t.title}
                    />
                  );
                }
                // REgex Insee
                if (t.id === 360002464399) {
                  return (
                    <Field
                      className="input__container"
                      key={t.id}
                      required={t.required}
                      name={`id-${t.id}`}
                      component={renderField}
                      type="text"
                      validate={[required, inseeCode]}
                      label={t.required ? t.title + " *" : t.title}
                    />
                  );
                }
                // Social Form
                if (
                  t.id === 360013237399 ||
                  t.id === 360013237439 ||
                  t.id === 360013237459
                ) {
                  return (
                    <Field
                      className="input__container"
                      key={t.id}
                      required={t.required}
                      name={`id-${t.id}`}
                      component={renderSocialField}
                      type="text"
                      validate={[rsxCode]}
                      label={t.required ? t.title + " *" : t.title}
                    />
                  );
                }
                return (
                  <Field
                    className="input__container"
                    key={t.id}
                    required={t.required}
                    name={`id-${t.id}`}
                    component={renderField}
                    type="text"
                    validate={t.required ? [required] : null}
                    label={t.required ? t.description + " *" : t.description}
                  />
                );
              case "textarea":
                return (
                  <Field
                    key={t.id}
                    required={t.required}
                    name={`id-${t.id}`}
                    className="textarea__container"
                    component={renderTextarea}
                    type="textarea"
                    validate={t.required ? [required] : null}
                    label={t.required ? t.description + " *" : t.description}
                  />
                );
              case "tagger":
                return (
                  <div key={t.id} data-tip={samples[t.id]}>
                    <label>
                      {t.required ? t.description + " *" : t.description}
                    </label>
                    <Field
                      name={`id-${t.id}`}
                      placeholder="Sélectionner une option"
                      component={renderSelect}
                      required={t.required}
                      options={t.custom_field_options}
                      validate={t.required ? [required] : ""}
                    >
                      {
                        <option value="" disabled>
                          {t.description}
                        </option>
                      }
                      {t.custom_field_options &&
                        t.custom_field_options.map((c, idx) => {
                          return (
                            <option
                              className="option"
                              key={idx}
                              value={c.value}
                            >
                              {c.name}{" "}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                );
              case "checkbox":
                if (t.id === 360002420960) {
                  return (
                    <div key={idx} data-tip={samples[t.id]}>
                      <Field
                        name={`id-${t.id}`}
                        id={t.id}
                        component={renderCheckBox}
                        label={
                          t.required ? t.description + " *" : t.description
                        }
                        type="checkbox"
                        className="checkbox"
                        validate={t.required ? [requiredCheck] : null}
                      />
                      <h3 className="option__title">Choix Optionnels</h3>
                      <p className="help_message">
                        {getHelpMessage(help, population)}
                      </p>
                      <br />
                    </div>
                  );
                } else if (t.id === 360002421419) {
                  return (
                    <div key={idx} data-tip={samples[t.id]}>
                      <h3>Choix Obligatoires</h3>
                      <Field
                        name={`id-${t.id}`}
                        id={t.id}
                        component={renderCheckBox}
                        label={
                          t.required ? t.description + " *" : t.description
                        }
                        type="checkbox"
                        className="checkbox"
                        validate={t.required ? [requiredCheck] : null}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={idx} data-tip={samples[t.id]}>
                      <Field
                        name={`id-${t.id}`}
                        id={t.id}
                        component={renderCheckBox}
                        label={
                          t.required ? t.description + " *" : t.description
                        }
                        type="checkbox"
                        className="checkbox"
                        validate={t.required ? [requiredCheck] : null}
                      />
                    </div>
                  );
                }
              default:
                return null;
            }
          })}

          <div>
            <small>Les champs marqués d'une * sont obligatoires</small>
            <div className="btn__container">
              {this.props.BackwardBtnValue === "" ? (
                ""
              ) : (
                <button onClick={(e) => this.onClickBackward(e)}>
                  {this.props.BackwardBtnValue || "Précédent"}
                </button>
              )}
              {this.props.ForwardBtnValue === "" ? (
                ""
              ) : (
                <button
                  onClick={(e) => this.onClickForward(e)}
                  disabled={this.props.invalid}
                  className={classNames({
                    isValid: !this.props.invalid,
                  })}
                >
                  {this.props.ForwardBtnValue || "Suivant"}
                </button>
              )}
              {this.props.canSubmit && (
                <div className="submit__btn">
                  <button
                    type="submit"
                    disabled={this.props.invalid || submitting}
                  >
                    {" "}
                    Je m'engage !
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const selector = formValueSelector("tdj_form");
Form = connect((state) => {
  const finishedSteps = state.finishedSteps.finishedSteps;
  const population = selector(state, "id-360002362180");
  return {
    population,
    finishedSteps,
  };
})(Form);

Form = reduxForm({
  form: "tdj_form",
  destroyOnUnmount: false,
})(Form);

export default withRouter(Form);
