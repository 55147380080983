import { FETCH_FORM, FETCH_ORDER_FORM } from '../../constants';


const initialState  = {
  ticket_fields:[]
};


export function initFormReducer(state = initialState, action)  {
   switch(action.type) {
    case `${FETCH_ORDER_FORM}_PENDING`:
        return {
            ...state,
            loading: true,
            error: false,
        };
    case `${FETCH_ORDER_FORM}_FULFILLED`:
        return {
            ...state,
            loading: false,
            error: false,
            form_order: action.payload.ticket_form.ticket_field_ids,
        };
    case `${FETCH_ORDER_FORM}_REJECTED`:
        return {
            ...state,
            loading: false,
            error: true,
        };
    case `${FETCH_FORM}_PENDING`:
        return {
            ...state,
            loading: true,
            error: false,
        };
    case `${FETCH_FORM}_FULFILLED`:
        return {
            ...state,
            loading: false,
            error: false,
            ticket_fields: action.payload.ticket_fields,
            metadatas: {'next_page':{next_page:action.payload.next_page},'previous_page':{previous_page:action.payload.previous_page},'count':{count:action.payload.count}},
        };
    case `${FETCH_FORM}_REJECTED`:
        return {
            ...state,
            loading: false,
            error: true,
        };
    

    default:
      return state;
  }
};


