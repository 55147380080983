import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { onResetAllFinish } from "../../../redux/actions/steps";
import { reset } from "redux-form";
class Success extends React.Component {
  render() {
    return (
      <div className="form__container">
        <div className="success__container">
          <div>
            <h1 className="icon bounce-top">
              <span role="img" aria-label="medaille">
                🏅
              </span>
            </h1>
            <br />
          </div>
          <div>
            <h1>Merci ! </h1>
            <h1>
              Votre candidature est à présent terminée.
              <br />
            </h1>
            <h2>
              Vous allez recevoir un email récapitulant les informations
              constituant votre dossier, accompagné des Conditions Générales
              d'Utilisation, nous vous invitons vivement à conserver ces
              informations.
              <br />
              <br />
            </h2>
            <p>
              {" "}
              En cas de non réception du mail de confirmation, nous vous
              conseillons de regarder dans les courriers indésirables. Sinon,
              vous pouvez nous contacter à l'adresse{" "}
              <a href="mailto:terredejeux2024@paris2024.org">
                terredejeux2024@paris2024.org
              </a>
            </p>
            <NavLink exact to="/">
              <button onClick={() => this.props.onResetForm()}>
                Retourner à l'accueil
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    loading: state.tdj_form.loading
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    onResetForm: () => {
      return dispatch(reset("tdj_form")), dispatch(onResetAllFinish());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Success);
