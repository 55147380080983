import {combineReducers} from 'redux';
import { initFormReducer } from './initFormReducer';
import { stepReducer } from './stepReducer';
import { submitFormReducer } from './submitFormReducer';
import { reducer as reduxFormReducer } from 'redux-form'

const rootReducer = combineReducers({
  tdj_form : initFormReducer,
  finishedSteps : stepReducer,
  response : submitFormReducer,
  form : reduxFormReducer
})

export default rootReducer;
