import React from "react";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
export const renderField = ({
  input,
  label,
  type,
  className,
  meta: { touched, error, warning },
}) => (
  <div className={className}>
    <label>{label}</label>
    <div className="input">
      <input {...input} placeholder={label} type={type} />
      {touched &&
        ((error && <span className="message__error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

export const renderSocialField = ({
  input,
  label,
  type,
  className,
  meta: { touched, error, warning },
}) => (
  <div className={className}>
    <label>{label}</label>
    <div className="input">
      <input {...input} placeholder="@mon-compte" type={type} />
      {touched &&
        ((error && <span className="message__error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

export const renderTextarea = ({
  input,
  label,
  type,
  className,
  meta: { touched, error, warning },
}) => (
  <div className={className}>
    <label>{label}</label>
    <div>
      <textarea {...input} placeholder={label} type={type} />
      {touched &&
        ((error && <span className="message__error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

export const renderCheckBox = ({
  input,
  label,
  type,
  className,
  meta: { touched, error, warning },
}) => (
  <div className={className}>
    <label
      className="container_checkbox"
      style={touched && error ? { marginBottom: 0 } : {}}
    >
      {label}
      <div className="input">
        <input {...input} type="checkbox" />

        <span className="checkmark"></span>
      </div>
      {touched &&
        ((error && <span className="checkbox__message__error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
      <ReactTooltip
        place="top"
        type="light"
        html={true}
        effect="solid"
        className="tooltip"
      />
    </label>
  </div>
);

const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "15px",
    color: "#cccccc",
    paddingLeft: "10px",
    paddingTop: "5px",
  }),
  container: (provided, state) => ({
    ...provided,
    marginTop: "5px",
    marginBottom: "30px",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0",
    border: "1px solid #efeded",

    "&:hover": {
      border: "1px solid #efeded",
      cursor: "pointer",
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  indicatorConntainer: (provided, state) => ({
    ...provided,
    color: "#efeded",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#383838",
    fontSize: "15px",
    padding: "20px ",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#292929",
      color: "#FFFFFF",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "17px",
    paddingLeft: "10px",
    paddingTop: "5px",
  }),

  menu: (provided, state) => ({
    ...provided,
    borderRadius: "0",
  }),
};

export const renderSelect = ({
  input,
  label,
  type,
  className,
  options,
  placeholder,
  meta: { touched, error, warning },
}) => {
  return (
    <div className="react-select-container">
      <Select
        {...input}
        value={input.label}
        onChange={(value) => input.onChange(value.value)}
        onBlur={(value) => input.onBlur(value.value)}
        blurInputOnSelect={false}
        options={options}
        isSearchable={false}
        placeholder={placeholder}
        styles={customStyles}
        simpleValue={true}
      />
      <ReactTooltip
        place="top"
        type="light"
        html={true}
        effect="solid"
        className="tooltip"
      />
      <div>
        {touched &&
          ((error && <span className="message__error">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};
