import { FETCH_FORM, FETCH_ORDER_FORM } from "../../constants";
import axios from "axios";

const ID_FORM_DEV = `https://paris2024.zendesk.com/api/v2/ticket_forms/${process.env.REACT_APP_ID_FORM_DEV}.json`;
const ID_FORM_PROD = `https://paris2024.zendesk.com/api/v2/ticket_forms/${process.env.REACT_APP_ID_FORM_PROD}.json`;

const config = {
  headers: {
    Authorization:
      "Bearer 1763f2abd768a4690c44810eb5a66c3078b8cde75a9ff8f190cd9e7e9951a909",
    "Content-Type": "application/json",
  },
};

export function fetchOrderForm() {
  const form =
    process.env.NODE_ENV === `development` ? ID_FORM_DEV : ID_FORM_PROD;
  return {
    type: FETCH_ORDER_FORM,
    payload: axios.get(form, config).then((r) => r.data),
  };
}
export function fetchForm() {
  return {
    type: FETCH_FORM,
    payload: axios
      .get("https://paris2024.zendesk.com/api/v2/ticket_fields.json", config)
      .then((r) => r.data),
  };
}
