import React, { useEffect } from "react";
import MediaQuery from "react-responsive";
import logo_Jo_black from "./../../img/logos/paris2024Color.svg";
import logo_Jo_white from "./../../img/logos/paris2024White.svg";
import logo_Tdj from "./../../img/logos/LogoTdj.svg";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
const Menu = (props) => {
  let location = useLocation().pathname;

  function customizeHeader() {
    // ADDCLASS NOT WORKING ON IE ...
    const header = document.getElementById("header");
    const headerHeight = header && header.offsetHeight;
    const logo = document.getElementById("logo");

    if (headerHeight < window.pageYOffset && window.innerWidth < 650) {
      // MOBILE SCROLL

      header.classList.add("fixed");
      logo.src = logo_Jo_black;
    } else if (headerHeight > window.pageYOffset && window.innerWidth < 650) {
      // MOBILE NOT SCROLL

      header.classList.remove("fixed");
      logo.src = props.logoBlack ? logo_Jo_black : logo_Jo_white;
    } else if (headerHeight < window.pageYOffset && window.innerWidth > 650) {
      // DESKTOP SCROLL

      header.classList.add("fixed");
      logo.src = logo_Jo_black;
    } else {
      // DESKTOP NOT SCROLL

      header.classList.remove("fixed");
      logo.src = props.logoBlack ? logo_Jo_black : logo_Jo_white;
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", customizeHeader);
    return () => {
      window.removeEventListener("scroll", customizeHeader);
    };
  });
  return (
    <div>
      <MediaQuery query="(min-width: 960px)">
        <div className="header__container" id="header">
          <div>
            <NavLink exact to="/">
              <img
                className="logo_jo"
                src={props.logoBlack ? logo_Jo_black : logo_Jo_white}
                alt="Logo"
                id="logo"
              />
            </NavLink>
          </div>

          <div>
            <img className="logo_tdj" src={logo_Tdj} alt="Logo" />
          </div>
          <div>
           {location !== "/exploreterredejeux2024" && <NavLink exact to="/exploreterredejeux2024">
              <button type="" style={{ marginRight: 20 }}>
                #exploreTerredeJeux2024
              </button>
            </NavLink>}
            <NavLink exact to="/formulaire/etape-0">
              <button type="">Je candidate</button>
            </NavLink>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery query="(max-width: 960px)">
        <div className="header__container" id="header">
          <div>
          <NavLink exact to="/">
            <img
              className="logo_jo"
              src={props.logoBlack ? logo_Jo_black : logo_Jo_white}
              alt="Logo"
              id="logo"
            />
            </NavLink>
          </div>
          <div>
            <img className="logo_tdj" src={logo_Tdj} alt="Logo" />
          </div>

          <NavLink exact to="/formulaire/etape-0">
            <button type="">Je candidate</button>
          </NavLink>
        </div>
      </MediaQuery>
    </div>
  );
};

export default Menu;
