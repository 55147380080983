export const labels = {
  360002464779: { description: "Code postal de la collectivité" },
  360005970580: { description: "Adresse de la collectivité" },
  360006009219: { description: "Ville de la collectivité" },
  360013237399: { description: "Compte Facebook" },
  360013237439: { description: "Compte Instagram" },
  360013237459: { description: "Compte Twitter" },
  360002384680: { description: "Prénom du contact principal" },
  360002467640: {
    description:
      "Organiser ces célébrations dans le respect des ambitions environnementales de Paris 2024",
  },
  360002362180: { description: "Renseignez le nombre d'habitants" },
  360002421319: {
    description: "Célébrer les Jeux dans votre territoire",
  },
  360002359119: {
    description:
      "Je certifie sur l’honneur avoir tous pouvoirs et/ou être dûment autorisé(e) à engager ma collectivité en vue de sa candidature au label Terre de Jeux 2024",
  },
  360002421339: {
    description:
      "Faire grandir la communauté Paris 2024 en suivant et relayant l'actualité du projet",
  },
  360002421359: {
    description:
      "Désigner un référent et participer aux activités de la communauté Terre de Jeux 2024",
  },
  360002466680: {
    description: "Prénom du responsable au plan juridique",
  },
  360002420860: {
    description:
      "De partager et de mettre en œuvre dans la mesure de ses moyens la Charte d’Excellence Environnementale de Paris 2024",
  },
  360002548860: { description: "Autres actions / projets" },
  360002464399: { description: "Code Insee de la collectivité" },
  360002362000: { description: "Type de la collectivité" },
  360002548119: { description: "Autres actions / projets" },
  360002464419: { description: "Fonction du contact principal" },
  360002548899: { description: "Autres actions / projets" },
  360002420900: {
    description:
      "Montrer comment l’aventure des Jeux se vit sur le terrain via des photos, vidéos, etc.",
  },
  360002421419: {
    description:
      "Favoriser la découverte du sport et de ses valeurs…urnée olympique, célébrée mondialement le 23 juin",
  },
  360002464519: { description: "Nom du responsable juridique" },
  360002466820: { description: "Téléphone du responsable juridique" },
  360002464939: {
    description: "Envisager des célébrations ouvertes au plus grand monde",
  },
  360002381999: { description: "Nom du contact principal" },
  360002464439: { description: "Email du contact principal" },
  360002421000: {
    description:
      "Faire du sport et des Jeux un levier de changement pour l'environnement",
  },
  360002421439: {
    description:
      "Favoriser la découverte des activités sportives tout au long de l’année",
  },
  360002466240: { description: "Nom de la collectivité" },
  360002466760: {
    description: "Fonction du responsable au plan juridique",
  },
  360002420940: {
    description:
      "Soutenir l'éducation par la sport à l'occasion de la Semaine olympique et paralympique dans les établissements scolaires",
  },
  360002421459: {
    description:
      "Favoriser le développement du sport-santé dans votre territoire",
  },
  360002464979: {
    description:
      "Vous reconnaissez avoir pris connaissance de la Conditions Générales d'Utilisation, d’en accepter l’esprit et les principes d’application sans réserve.",
  },
  360002466780: {
    description: "E-mail du responsable au plan juridique",
  },
  360002479580: {
    description:
      "Souhaiteriez-vous candidater pour devenir Centre de Préparation aux Jeux ?",
  },
  360002420960: {
    description:
      "Promouvoir la pratique sportive auprès des élus et du personnel de votre collectivité",
  },
  360002421479: {
    description:
      "Promouvoir la formation et la valorisation des bénévoles du mouvement sportif",
  },
  360002466540: { description: "Téléphone du contact principal" },
  360002548719: {
    description:
      "Faire du sport un moteur de valorisation du territoire et de développement économique",
  },
  360002420980: {
    description:
      "Soutenir le déploiement du label Génération 2024 pour les établissements scolaires et universitaires",
  },
  360002421499: {
    description:
      "Accompagner les sportifs de haut niveau dans leur carrière sportive et/ou leur reconversion",
  },
  360015410659: {
    description : "Accepter de partager votre e-mail aux autres labelisés"
  }
};
