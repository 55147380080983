export const getHelpMessage = (help,population) => {
    if ( help && population ) {
        switch ( population ) {
            case 'size_1':
                return (
                    "Merci de cocher au moins un choix dans la liste de choix optionnels ci-dessous. Attention, les demandes irrégulières ou incomplètes ne pourront pas faire l'objet de l'attribution du Label"
                )
            case 'size_2':
                return (
                    "Merci de cocher au moins un choix dans la liste de choix optionnels ci-dessous. Attention, les demandes irrégulières ou incomplètes ne pourront pas faire l'objet de l'attribution du Label"
                )
            case 'size_3':
                return (
                    "Merci de cocher au moins deux choix dans la liste de choix optionnels ci-dessous. Attention, les demandes irrégulières ou incomplètes ne pourront pas faire l'objet de l'attribution du Label"
                )
            case 'size_4':
                return (
                    "Merci de cocher au moins deux choix dans la liste de choix optionnels ci-dessous. Attention, les demandes irrégulières ou incomplètes ne pourront pas faire l'objet de l'attribution du Label"
                )
            case 'size_5':
                return (
                    "Merci de cocher au moins quatre choix dans la liste de choix optionnels ci-dessous. Attention, les demandes irrégulières ou incomplètes ne pourront pas faire l'objet de l'attribution du Label"
                )
            default:
                return null;
        }
    }
}
