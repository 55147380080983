import React from 'react';
import { NavLink } from 'react-router-dom';




class Error extends React.Component {

  render() {
    return (
      <div className="form__container">
        <div className="loader">
        </div>
        <div className="success__container">
          <div>
            <h1 className="icon bounce-top"><span role="img" aria-label="défaite">🥊</span></h1><br />
          </div>
          <div>
            <h1>
              Aïe<br />
            </h1>
            <h2>nous n’avons pas pu finaliser votre candidature cette fois. <br />Veuillez réessayer ultérieurement.
            </h2>
            <NavLink
              exact
              to="/formulaire/etape-6">
              <button>Renvoyer</button>
            </NavLink>
          </div>
        </div>
      </div>
    )
  }
}
export default Error;

