import React from "react";

import { onStepIsFinish, onResetFinish } from "../../../redux/actions/steps";

import Form from "../../../components/ReduxForm";
import { connect } from "react-redux";

import { formatTicket } from "../../../utils/formatPayload";
class RenderForm extends React.Component {
  onClickBackward = (e) => {
    this.props.history.push({
      pathname: "etape-0",
    });
  };

  onClickForward = (e) => {
    this.props.history.push({
      pathname: "etape-2",
    });
  };

  render() {
    const path = this.props.location.pathname.replace("/formulaire/", "");
    return (
      this.props.order !== undefined &&
      !this.props.loading && (
        <Form
          ticket_fields={formatTicket(
            this.props.ticket_fields,
            this.props.order,
            11,
            22
          )}
          title="1. Informations collectivité"
          category="identification"
          help={false}
          onClickBackward={(e) => this.onClickBackward(e)}
          onClickForward={(e) => this.onClickForward(e)}
          stepIsFinish={(e) => this.props.onStepIsFinish(path)}
          resetFinish={(e) => this.props.onResetFinish(path)}
          values={this.props.values}
        />
      )
    );
  }
}

export function mapStateToProps(state) {
  return {
    loading: state.tdj_form.loading,
    error: state.tdj_form.error,
    ticket_fields: state.tdj_form.ticket_fields,
    metadatas: state.tdj_form.metadatas,
    order: state.tdj_form.form_order,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    onStepIsFinish: (path) => dispatch(onStepIsFinish(path)),
    onResetFinish: (path) => dispatch(onResetFinish(path)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderForm);
