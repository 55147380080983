import {submitForm, submitMail} from './../actions/submitForm'

export function submitFormAndMail(values) {
  return (dispatch, getState) => {
  
    dispatch(submitForm(values)).then(
        () => dispatch(submitMail(values))
      )
  };
}
