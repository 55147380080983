import React from "react";
import { connect } from "react-redux";
import { onStepIsFinish, onResetFinish } from "../../../redux/actions/steps";
class RenderForm extends React.Component {
  onClickForward = path => {
    this.props.history.push({
      pathname: "etape-1"
    });
    this.props.onStepIsFinish(path);
  };

  render() {
    const path = this.props.location.pathname.replace("/formulaire/", "");
    return this.props.order !== undefined && !this.props.loading ? (
      <div className="step__container">
        <div className="step">
          <h1>Vos engagements</h1>
          <h3>
            <strong>
              Etre engagé au service d’une ambition : mettre plus de sport dans
              la vie des Français.
            </strong>{" "}
            <br />
            Plus de sport pour l’éducation, la santé, l’inclusion. <br />
            Plus de sport durable et responsable. <br />
            Plus de sport accessible à tous, sans distinction d’âge, de genre,
            de lieu de résidence, d’orientation sexuelle, d'origine ou de
            condition physique.
            <br />
            Plus de sport pour vivre des émotions uniques.
            <br />{" "}
          </h3>
          <h3>
            <strong>Partager une méthode : faire ensemble.</strong>
            <br />
            Avec le mouvement sportif local et les autres collectivités.
            <br />
          </h3>

          <h3>
            {" "}
            <strong>
              Mener des actions concrètes, autour de trois grands objectifs
            </strong>{" "}
            <br />
            Une célébration ouverte, pour faire vivre à tous, les émotions du
            sport et des Jeux.
            <br />
            Un héritage durable, pour changer le quotidien des Français grâce au
            sport.
            <br />
            Un engagement inédit,pour donner au plus grand nombre la chance de
            vivre l’aventure des Jeux dès maintenant, partout en France.
          </h3>

          <button onClick={e => this.onClickForward(path)}>Je Valide </button>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export function mapStateToProps(state) {
  return {
    loading: state.tdj_form.loading,
    error: state.tdj_form.error,
    ticket_fields: state.tdj_form.ticket_fields,
    order: state.tdj_form.form_order
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    onStepIsFinish: path => dispatch(onStepIsFinish(path)),
    onResetFinish: path => dispatch(onResetFinish(path))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderForm);
