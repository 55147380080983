import React from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import logo_Tdj from "./../../img/logos/LogoTdj.svg";
const steps = [
  {
    title: "philosophie",
    url: "etape-0"
  },
  {
    title: "collectivité",
    url: "etape-1"
  },
  {
    title: "contact référent",
    url: "etape-2"
  },
  {
    title: "contact responsable",
    url: "etape-3"
  },
  {
    title: "célébration",
    url: "etape-4"
  },
  {
    title: "héritage",
    url: "etape-5"
  },
  {
    title: "engagement",
    url: "etape-6"
  },
  {
    title: "Conditions Générales",
    url: "etape-7"
  }
];

const Timeline = props => {
  const path = props.location.pathname.replace("/formulaire/", "");
  return (
    <div className="timeline__container">
      <h3 className="logo">
        <NavLink exact to="/">
          <img src={logo_Tdj} alt="Terre de Jeux 2024" />
        </NavLink>
      </h3>

      {steps.map((step, i) => {
        return (
          <NavLink
            key={i}
            exact
            to={`/formulaire/${step.url}`}
            onClick={
              !props.finishedSteps.includes(step.url)
                ? e => e.preventDefault()
                : e => e.persist()
            }
          >
            <div>
              <h3
                className={classNames({
                  inactive: !props.finishedSteps.includes(step.url)
                })}
              >
                <span
                  className={classNames("dot", {
                    active: path === step.url,
                    finished: props.finishedSteps.includes(step.url)
                  })}
                ></span>
                {step.title}
              </h3>
              <span className="line"></span>
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default withRouter(Timeline);
