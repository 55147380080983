import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import rootReducer  from '../reducers'
import { createBrowserHistory } from 'history';
import axios from 'axios';

export const history = createBrowserHistory();

const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
      Accept: 'application/json',
  },
});


const middlewares = [promise,thunk.withExtraArgument(axiosInstance)];


if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}
export const store = createStore(rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);


