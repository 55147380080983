// validation functions
export const required = (value) =>
  value == null ? "Merci de remplir ce champs" : undefined;
export const requiredCheck = (checked) =>
  checked === false || checked == null
    ? "Merci de cocher ce champs"
    : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Adresse e-mail invalide"
    : undefined;

export const zipCode = (value) =>
  value && !/^(?:[0-8]\d|9[0-8])\d{3}$/i.test(value)
    ? "Code Postale invalide (ne pas mettre d'espace)"
    : undefined;

export const phone = (value) =>
  value && !/^((\+)33|0)[1-9](\d{2}){4}$/i.test(value)
    ? "Numéro de téléphone invalide (ne pas mettre d'espace)"
    : undefined;

export const inseeCode = (value) =>
  value && !/^(2[AB]|[0-9]{2})[0-9]{3}$/i.test(value)
    ? "Code INSEE invalide (doit contenir 5 chiffres)"
    : undefined;

export const rsxCode = (value) =>
  value && !/^@/.test(value) ? "Votre compte doit commencer par @" : undefined;
