import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchForm } from "./redux/actions/initForm";

import Home from "./components/Home";
import Form from "./containers/Form";
import Explore from "./containers/Explore";
import "./App.css";

class App extends Component {
  componentDidMount() {
    this.props.startFetching();
    console.log("Fait avec ♥ par Fragile : Charles d'Oiron & David Benmussa");
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/formulaire/:id" component={Form} />
          <Route exact path="/ExploreTerreDeJeux2024" component={Explore} />
          <Route path="/" component={Home} />
        </Switch>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    loading: state.tdj_form.loading,
    error: state.tdj_form.error,
    ticket_fields: state.tdj_form.ticket_fields,
    metadatas: state.tdj_form.metadatas,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    startFetching: () => dispatch(fetchForm()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
