import React from 'react';
import Timeline from './../../containers/Timeline'


export const Layout = props => {
  return (
    <div className="layout__container">
      <Timeline />
      <React.Fragment>
        { props.children }
      </React.Fragment>


    </div>
  )
}
