import React, { Component } from "react";
import Slider from "react-slick";
import { slides } from "../../static/mapSlides";

export default class MapSlider extends Component {
  render() {
    const settings = {
      dots: false,
      fade: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      arows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <Slider {...settings}>
        {slides.map((slide) => {
          return (
            <div style={{ width: "100%" }}>
              <img src={slide.path} style={{ width: "100%" }} alt="slides" />
            </div>
          );
        })}
      </Slider>
    );
  }
}
