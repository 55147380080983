import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router"
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { history } from './redux/store';
import * as Sentry from '@sentry/browser';
import "typeface-source-sans-pro";
import App from './App';
import './App.css';


Sentry.init({dsn: "https://376147c7d294456b9dba343a11d6126c@sentry.io/1514173"});


ReactDOM.render(
    <Provider store={ store }>

        <Router history={ history }>
            <App />
        </Router>
    </Provider>,
    document.getElementById( 'root' )
);
