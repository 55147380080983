export const samples = {
  "360002421339":
    "<p>Relayer les informations Paris 2024 sur le site Internet de la collectivité et/ou sur ses réseaux sociaux</p><p>Afficher les actualités de Paris 2024 dans les locaux de la collectivité et/ou dans les équipements sportifs de la collectivité</p>",
  "360002420900":
    "<p>Partager des contenus qui démontrent une mobilisation à l'occasion d'un événement lié à Paris 2024</p><p>Mettre en lumière l'engagement des bénévoles dans les associations sportives et mobilisés pour le sport français dans la perspective des Jeux</p>",
  "360002421359":
    "<p>Désigner un référent Paris 2024 (élu ou employé) dans votre collectivité en charge de tous les contacts avec Paris 2024</p><p>Participer à un événement Terre de Jeux 2024 organisé par Paris 2024, votre région ou un autre acteur territorial</p>",

  "360002421319":
    "<p>Organiser un moment de partage autour de la diffusion d’une épreuve sportive des Jeux Olympiques et Paralympiques de Tokyo 2020</p><p>Organiser une activité artistique ou culturelle autour des valeurs olympiques et paralympiques dans un club sportif, une association, une école…</p>",
  "360002467640":
    "<p>Engager une démarche de réduction des déchets lors des événements Terre de Jeux 2024</p>",
  "360002464939":
    "<p>Proposer un accès amélioré pour les personnes en situation de handicap</p><p>S'attacher à organiser et à communiquer autour d'événements inclusifs et ouverts à tous</p>",

  "360002421419":
    "<p>Organiser une activité permettant de découvrir une pratique sportive ou parasportive</p><p>Organiser une épreuve sportive mixte et intergénérationnelle autour d’un ou plusieurs sports</p>",
  "360002420940":
    "<p>Diffuser les informations fournies par Paris 2024 aux établissements scolaires du territoire</p><p>Proposer un soutien aux établissements et clubs engagés ou souhaitant s’engager dans la Semaine olympique et paralympique</p>",
  "360002420960":
    "<p>Informer sur les offres de pratique sportive existantes dans la collectivité ou à proximité (clubs sportifs, aires de pratique en libre-accès…)</p><p>Organiser une séance de sport pour faire découvrir une discipline aux élus et employés de la collectivité</p>",
  "360002421439":
    "<p>Déployer localement des programmes nationaux autour du sport (ex. : 'Savoir rouler à vélo', 'J'apprends à nager' du Ministère ou 'Jeux des jeunes' du CNOSF)</p><p>Mettre en place une activité de découverte sportive</p>",
  "360002420980":
    "<p>Accompagner un projet entre un établissement scolaire et un club local (événement commun, voyage scolaire…)</p><p>Soutenir des projets éducatifs autour des Jeux</p>",
  "360002421459":
    "<p>Soutenir le développement d’une offre sport-santé dans un club sportif local</p><p>Créer un dispositif sport-santé dans l’ensemble de la collectivité</p>",
  "360002421000":
    "<p>Créer ou relayer des programmes de sensibilisation et de formation à l’excellence environnementale du sport à destination des clubs locaux</p><p>Intégrer une action éco-responsable dans une manifestation sportive locale, un équipement sportif ou l’activité d’un club sportif</p>",
  "360002421479":
    "<p>Accompagner un jeune de la collectivité pour devenir bénévole dans le cadre d’un événement régional ou national</p><p>Organiser une cérémonie annuelle de remerciement et de mise en valeur des bénévoles</p>",
  "360002421499":
    "<p>Soutenir et valoriser un athlète de haut niveau licencié dans un club local</p><p>Accompagner un athlète de haut niveau pour préparer sa reconversion professionnelle</p>",
  "360002548719":
    "<p>Créer ou renforcer vos activités touristiques sportives</p><p>Soutenir un dispositif d’insertion professionnelle ou d'innovation par le sport</p>",
  "360002548739":
    "<p>Mettre en place des animations et temps d’échange autour du sport et des Jeux avec des collectivités étrangères</p><p>Créer ou renforcer un programme de coopération internationale en lien avec le sport</p>",
  "360002479580":
    "<p> Les Centres de Préparation aux Jeux permettent aux collectivités de référencer leurs équipements sportifs dans le cadre de l’accueil des délégations olympiques et paralympiques au cours de leur préparation aux Jeux de Paris 2024.</p>"
};
