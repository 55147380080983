import React from "react";

import Form from "../../../components/ReduxForm";
import { onStepIsFinish, onResetFinish } from "../../../redux/actions/steps";

import { connect } from "react-redux";
import { formatTicket } from "../../../utils/formatPayload";

class RenderForm extends React.Component {
  onClickBackward = (e, type, population) => {
    this.props.history.push({
      pathname: "etape-1",
    });
  };

  onClickForward = (e) => {
    this.props.history.push({
      pathname: "etape-3",
    });
  };

  render() {
    const path = this.props.location.pathname.replace("/formulaire/", "");
    return (
      this.props.order !== undefined &&
      !this.props.loading && (
        <Form
          ticket_fields={formatTicket(
            this.props.ticket_fields,
            this.props.order,
            22,
            27
          )}
          title="2. Coordonnées du référent"
          category="something"
          onClickBackward={(e) => this.onClickBackward(e)}
          onClickForward={(e) => this.onClickForward(e)}
          stepIsFinish={(e) => this.props.onStepIsFinish(path)}
          resetFinish={(e) => this.props.onResetFinish(path)}
        />
      )
    );
  }
}

export function mapStateToProps(state) {
  return {
    loading: state.tdj_form.loading,
    error: state.tdj_form.error,
    ticket_fields: state.tdj_form.ticket_fields,
    metadatas: state.tdj_form.metadatas,
    order: state.tdj_form.form_order,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    onStepIsFinish: (path) => dispatch(onStepIsFinish(path)),
    onResetFinish: (path) => dispatch(onResetFinish(path)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderForm);
