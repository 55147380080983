import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'

import {Layout} from './../../components/Layout'
import {initializeForm} from '../../redux/thunk/fetchForm';



import Sticky from './../Sticky'
import Step_00 from './Step_00'
import Step_01 from './Step_01'
import Step_02 from './Step_02'
import Step_03 from './Step_03'
import Step_04 from './Step_04'
import Step_05 from './Step_05'
import Step_06 from './Step_06'
import Step_07 from './Step_07'
import Success from './Success'
import Error from './Error'





class Form extends React.Component {
  componentDidMount() {
    this.props.startFetching()
  }
  render() {
    const etape_0 = "etape-0";
    const etape_7 = "etape-7";
    const success = "success";
    const error = "error";
    const path = this.props.location.pathname.replace('/formulaire/', '');

    return (
      <Switch>

        <Layout>

          {/* In production, redirect to step 0 if user changes manualy the url */}
          {process.env.NODE_ENV !== `development` && (this.props.finishedSteps.length === 0) && (path !== etape_0) ? <Redirect to="/formulaire/etape-0" /> : null}


          <Sticky show={((path !== etape_0) && (path !== etape_7) && (path !== success) && (path !== error))} />
          <Route exact path="/formulaire/etape-0" component={Step_00} />
          <Route exact path="/formulaire/etape-1" component={Step_01} />
          <Route exact path="/formulaire/etape-2" component={Step_02} />
          <Route exact path="/formulaire/etape-3" component={Step_03} />
          <Route exact path="/formulaire/etape-4" component={Step_04} />
          <Route exact path="/formulaire/etape-5" component={Step_05} />
          <Route exact path="/formulaire/etape-6" component={Step_06} />
          <Route exact path="/formulaire/etape-7" component={(props) => <Step_07 {...props} response={this.props.response} />} />
          <Route exact path="/formulaire/success" component={Success} />
          <Route exact path="/formulaire/error" component={Error} />
        </Layout>

      </Switch>
    )
  }
}


export function mapStateToProps(state) {
  return {
    response: state.response,
    finishedSteps: state.finishedSteps.finishedSteps,
    loading: state.tdj_form.loading,
    order: state.tdj_form.form_order,

  }
};

export function mapDispatchToProps(dispatch) {
  return {
    startFetching: () => dispatch(initializeForm()),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
